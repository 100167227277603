import makeRequest from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import url from "../api/urls";

export class AuthServices {
    static async login(payload: any) {
        return await makeRequest(url.login, RequestMethods.POST, payload)
    }

    static async sendOTP(payload: any) {
        return await makeRequest(url.sendOtp, RequestMethods.POST, payload)
    }

    static async verifyOTP(payload: any) {
        return await makeRequest(url.verifyOtp, RequestMethods.POST, payload)
    }
}