import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import TablePaginationComponent from '../../component/table/TablePagination';
import { resolveModuleName } from 'typescript';
import { FaFileImage, FaFilePdf } from 'react-icons/fa';
import PreviewModal from '../../component/modals/Preview.modal';
import moment from 'moment';
import DownloadCsv from '../../component/DownloadCsv';

export default function CSRReport() {

    const [shifts, setShifts] = useState<any>();
    const [selectValue, setSelectValue] = useState<any>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalCount, setTotolCount] = useState<number>(0);
    const [csrData, setCsrData] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>()



    const [showPreviewModal, setShowPreviewModal] = useState<any>(undefined)

    const [centers, setCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();






    const getUniqueCenter = async () => {
        await ClientService.getUniqueCenter2(selectValue).then(res => {
            if (res.status === 200) {
                setCenters(
                    res.data?.centers.map((zone: any) => ({
                        value: zone?.centerPreference,
                        label:  zone?.allotedCenterId + " - " + zone?.centerPreference,
                    }))
                );
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getFileTypeFromUrl = (url: any) => {
        const fileExtensions: any = {
            '.pdf': 'pdf',
            '.jpg': 'image',
            '.jpeg': 'image',
            '.png': 'image',
            '.gif': 'image'
        };

        const extension = url.slice(url.lastIndexOf('.')).toLowerCase();

        return fileExtensions[extension] || 'unknown';
    };

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllCSRReport = async () => {
        await ClientService.getAllCSRReport(selectValue, pageSize, pageNumber, selectedCenters?.value).then((res) => {
            if (res.status === 200) {
                setCsrData(res?.data?.data?.CSRReports);
                setTotolCount(res.data.data.totalCsrReports)
            }
        })
    }

    async function createCsv(): Promise<any[]> {
        return csrData?.map((data: any) => ({
            "Biometric Operator Username": data?.invigilatorId?.userName || "--",
            "Center Name": data?.centerId || "--",
            "Exam Name": data?.examId?.name || "--",
        })) || [];
    }

    useEffect(() => {
        if (selectValue) {
            getUniqueCenter();
        }
    }, [selectValue])



    useEffect(() => {
        if (selectValue) {
            getAllCSRReport();
        }
    }, [selectValue, pageNumber, selectedCenters])

    useEffect(() => {
        getAllShift();
    }, [])

    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">CSR Report</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectValue(e?.value)}
                        isClearable
                        value={selectValue ? shifts.find((shift: any) => shift.value === selectValue?.value) : null}
                    />
                </Col>
            </Row>
            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label className="text-muted fw-bold">
                                    Venue
                                </Form.Label>
                                <Select
                                    options={centers}
                                    // options={[{ label: "All", value: "All" }, ...(centers?.map((state: any) => ({ label: state?.label, value: state?.value })) || [])]}
                                    onChange={(e: any) => setSelectedCenters(e)}
                                    value={selectedCenters}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isClearable
                                // isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* {
                csrData?.length > 0 &&
                <DownloadCsv
                    headers={["Biometric Operator Username", "Center Name", "Exam Name"]}
                    createCsv={createCsv}
                    fileName="CSR_Report"
                />
            } */}

            <Row>
                <Col>
                    <Card
                        className="mt-3"
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <Card.Body>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Operator Name</th>
                                        <th>Operator Id</th>
                                        <th>Venue ID</th>
                                        <th>Venue Name</th>
                                        <th>Exam Name</th>
                                        <th>Report</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {csrData?.length > 0 ? csrData?.map((data: any, index: any) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{data?.invigilatorId?.operatorName}</td>
                                                <td>{data?.invigilatorId?.operatorId}</td>
                                                <td>{data?.centerId || "--"}</td>
                                                <td>{data?.centerPreference || "--"}</td>
                                                <td>{data?.examId?.name}</td>
                                                <td>
                                                    {data?.CSRReport?.map((fileUrl: any, index: number) =>
                                                        getFileTypeFromUrl(fileUrl) === "image" ? (
                                                            <FaFileImage key={index} onClick={() => setShowPreviewModal(fileUrl)} />
                                                        ) : (
                                                            <a href={fileUrl} download={true} className="text-decoration-none">
                                                                <FaFilePdf key={index} />
                                                            </a>
                                                        )
                                                    )}
                                                </td>

                                            </tr>
                                        )
                                    }) : "No data found"}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TablePaginationComponent
                        currentPage={pageNumber}
                        itemsCount={totalCount}
                        itemsPerPage={pageSize}
                        setItemsPerPage={(e: any) => setPageSize(e)}
                        setCurrentPage={setPageNumber}
                    />
                </Col>
            </Row>

            <PreviewModal
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(undefined)}

            />
        </>
    )
}
