import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Form, Image, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import moment from 'moment';
import { VerificationProcessEnum } from '../../component/enum/VerficationProcess.enum';
import SplitPreviewModal from '../../component/modals/SplitPreview.modal';
import ManualVerificationModal from '../../component/modals/ManualVerification.modal';
import { FaEye } from 'react-icons/fa6';

export default function SearchIndex() {
  const [selected, setSelected] = useState<any>({});
  const [shifts, setShifts] = useState<any[]>([]);
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);


  const [splitPreviewData, setSplitPreviewData] = useState<any>(null);
  const [manualVerificationData, setManualVerificationData] = useState<any>({});


  const getAllShift = async () => {
    await ClientService.getAllShifts()
      .then(res => {
        if (res.status === 200) {
          setShifts(res.data.shifts.map((data: any) => {
            return {
              label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
              value: data._id
            }
          }))
          // setShiftDate(res.data.shifts.map((data: any) => {
          //   return {
          //     label: moment(data.startTime).format("DD MMM YYYY"),
          //     value: data._id
          //   }
          // }))
        }
      }).catch(err => {
        toast.error(err?.response?.data || 'Something went wrong');
      })
  }

  const getUserData = async () => {
    await ClientService.getDatabyUserId(selectedShift, selected?.value)
      .then((res) => {
        if (res.status === 200) {
          if (res.status === 200) {
            // console.log(res.data, "userData")
            setUserData(res.data);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data || 'Something went wrong');
      })
  }


  const search = async (inputValue: string): Promise<any> => {
    if (inputValue.length < 4) return [];
    try {
      const res = await ClientService.searchStudentByRollNo(selectedShift, inputValue);
      if (res.status === 200) {
        const options = res.data.map((data: any) => ({
          label: `${data.rollNo} - ${data.name}`,
          value: data._id
        }));
        return options;
      }
    } catch (err: any) {
      toast.error(err?.response?.data || 'Something went wrong');
    }
    return [];
  };

  // console.log(manualVerificationData, "manulalVerificationData")

  useEffect(() => {
    if (selected?.value) {
      getUserData();
    }
  }, [selected])

  useEffect(() => {
    getAllShift();
  }, []);


  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center mt-5">
        <h4 className="d-flex text-secondary">Student Search</h4>
        <div className="d-flex">
          <Select
            options={shiftDate}
            onChange={(e: any) => setSelectedShiftDate(e)}
            placeholder="Select shift Date"
            isClearable
          />
          <Select
            options={
              selectedShiftDate
                ? shifts?.filter((shift: any) => shift.value === selectedShiftDate.value)
                : []
            }
            onChange={(e: any) => setSelectedShift(e?.value)}
            placeholder="Select shift"
            className="ms-3"
            isClearable
          />
        </div>
      </div> */}
      <Row className='mt-3'>
        <Col md={4}>
          <Form.Label className="text-muted fw-bold">
            Select Shift
          </Form.Label>
          <Select
            options={shifts}
            placeholder="Select Shifts"
            onChange={(e: any) => setSelectedShift(e?.value)}
            isClearable
            value={selectedShift ? shifts.find((shift: any) => shift.value === selectedShift) : null}
          />
        </Col>
      </Row>


      {
        !selectedShift ? (
          <>
            <Card
              className="mt-3"
              style={{
                backgroundColor: "#f9f9f9",
                borderRadius: "12px",
                border: "none",
              }}
            >
              <Card.Body>
                Select Shift First
              </Card.Body>
            </Card>
          </>
        ) :
          <Card
            className="mt-3"
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "12px",
              border: "none",
            }}
          >
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className='d-flex'>Enter Roll Number to Search Student</Form.Label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={search}
                      value={selected}
                      onChange={setSelected}
                      placeholder={`Search`}
                      isClearable
                      className='text-start'
                      isSearchable
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
      }

      {/* 24001 */}

      {
        userData &&

        <Card
          className="mt-3"
          style={{
            backgroundColor: "#f9f9f9",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <Card.Body>
            <Row className="mt-3">
              <Col md={12} className=''>
                <h5 className='text-center'>
                  User Details
                </h5>
                <div>
                  {/* <div className="d-flex align-items-center mb-1">
                    <div style={{ width: 100, height: 100 }}>
                      <Image src={userData?.user?.avatar} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} />
                    </div>
                  </div> */}
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Name:</p>
                    <p className="flex-grow-1">{userData?.user?.name}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Roll No.:</p>
                    <p className="flex-grow-1">{userData?.user?.rollNo}</p>
                  </div>
                  {/* <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Gender:</p>
                    <p className="flex-grow-1">{userData?.user?.gender}</p>
                  </div> */}
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>State:</p>
                    <p className="flex-grow-1">{userData?.user?.state}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Division:</p>
                    <p className="flex-grow-1">{userData?.user?.zone}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>City:</p>
                    <p className="flex-grow-1">{userData?.user?.city}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Center:</p>
                    <p className="flex-grow-1">{userData?.user?.centerPreference}</p>
                  </div>
                </div>
                <hr />
                <div>
                  <h5 className="mb-2">
                    Exam Details
                  </h5>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Exam Name:</p>
                    <p className="flex-grow-1">{userData?.user?.courseName}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live:</p>
                    <p className="flex-grow-1">{userData?.exam?.isLive ? <span className="fw-bold text-success">Live</span> : <span className="fw-bold text-danger">Not Live</span>}</p>
                  </div>
                </div>
                <hr />
                <div>
                  <h5 className="mb-2">
                    Shift Details
                  </h5>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Shift Name:</p>
                    <p className="flex-grow-1">{userData?.shift?.shiftName}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Start Time:</p>
                    <p className="flex-grow-1">{moment(userData?.shift?.startTime).format("DD-MM-YYYY hh:mm a")}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>End Time:</p>
                    <p className="flex-grow-1">{moment(userData?.shift?.endTime).format("DD-MM-YYYY hh:mm a")}</p>
                  </div>
                </div>
                <hr />
                <div className="mt-2">
                  <h5>
                    Verifications
                  </h5>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Center Id:</p>
                    <p className="flex-grow-1">{userData?.verifications?.centerId}</p>
                  </div>
                  <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Biometric Operator:</p>
                    <p className="flex-grow-1">{userData?.verifications?.invigilatorId?.userName}</p>
                  </div>
                  {/* <div className="d-flex align-items-center mb-1">
                    <p className="flex-shrink-0" style={{ fontWeight: "500", width: "200px" }}>Live Photo:</p>
                    <div style={{ width: 70, height: 70 }}>
                      <Image src={userData?.verifications?.userLivePhoto} width={"100%"} height={"100%"} style={{ borderRadius: "50%" }} onClick={() => setSplitPreviewData({ uploadPhoto: userData?.user?.avatar, livePhoto: userData?.verifications?.userLivePhoto })} />
                    </div>
                  </div> */}
                  <div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Verification Method</th>
                          <th>Success</th>
                          <th>Manually Verification</th>
                          <th>Success Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData?.verifications && userData?.verifications?.verificationDetails?.map((data: any, index: number) => {
                          return (
                            <tr>
                              <td className='text-align-center'>{index + 1}</td>
                              <td className='text-align-center text-capitalize'>
                                {data?.method === "fingerprint" ? "Right Fingerprint" : data?.method === "fingerprint2" ? "Left Fingerprint" : data?.method}
                              </td>
                              <td>
                                <Badge bg={data?.success ? "success" : "danger"}>{data?.success ? "Success" : "Failed"}</Badge>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  <Badge bg={data?.manuallyVerified ? "warning" : "success"}>{data?.manuallyVerified ? "Yes" : "No"}</Badge>
                                  {
                                    data?.manuallyVerified &&
                                    <FaEye style={{ cursor: "pointer" }} onClick={() => setManualVerificationData(data)} />
                                  }
                                </div>
                              </td>
                              <td>{data?.success_time ? moment(data?.success_time).format("DD/MM/YYYY hh:mm a") : "-"}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

      }
      <SplitPreviewModal
        show={splitPreviewData}
        handleClose={() => setSplitPreviewData(null)}
      />

      <ManualVerificationModal
        data={manualVerificationData}
        handleClose={() => setManualVerificationData(null)}
      />
    </>
  )
}