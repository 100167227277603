import React, { useState } from "react";
import { Button, Card, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { AuthServices } from "../../../../component/services/auth.service";
import toast from "react-hot-toast";
import Auth from "../../../../component/Auth/auth";
import { useNavigate } from "react-router-dom";

export default function LoginBoxOTP() {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [step, setStep] = useState("email");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSendOTP = async (e: any) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError("Please enter a valid email address");
            setLoading(false);
            return;
        }

        await AuthServices.sendOTP({ email })
            .then((res) => {
                if (res.status === 200) {
                    toast.success('OTP sent successfully');
                    setStep("otp");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err?.response?.data?.message || err?.response?.data || "Something went wrong");
            })
            .finally(() => setLoading(false));
    };

    const handleVerifyOTP = async (e: any) => {
        e.preventDefault();
        setError("");
        setLoading(true);

        if (otp.length !== 4) {
            setError("OTP must be 4 digits");
            setLoading(false);
            return;
        }

        await AuthServices.verifyOTP({ email, otp })
            .then((res) => {
                if (res.status === 200) {
                    Auth.authenticate();
                    sessionStorage.setItem("authKey", res.data.data.token)
                    navigate('/dashboard');
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err?.response?.data || "Something went wrong");
                if (err.response.status === 403) {
                    console.error(err);
                } else if (err.response.status === 500) {
                    toast.error(err.response.data)
                }
            })
            .finally(() => setLoading(false));
    };

    const handleResendOTP = () => {
        setError("");
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 1500);
    };

    return (
        <Card className="border-0 shadow mb-5" style={{ width: "30rem", borderRadius: "0.5rem", marginTop: "4rem" }}>
            <Card.Title className="text-center text-primary mb-2 mt-4">
                <h4>
                    Client Login <FontAwesomeIcon icon={faUserTie} />
                </h4>
            </Card.Title>
            <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}

                {step === "email" ? (
                    <Form onSubmit={handleSendOTP}>
                        <Form.Group controlId="formBasicEmail" className="mt-3">
                            <Form.Label className="fw-bold">Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <Form.Text className="text-muted">
                                We'll send a one-time password to this email.
                            </Form.Text>
                        </Form.Group>

                        <div className="form-group mt-4">
                            <Button
                                variant="primary"
                                className="w-100"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? "Sending..." : "Send OTP"} <FontAwesomeIcon icon={faKey} />
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <Form onSubmit={handleVerifyOTP}>
                        <Form.Group controlId="formBasicOTP" className="mt-3">
                            <Form.Label className="fw-bold">Enter OTP</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter 4-digit OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value.replace(/\D/, "").slice(0, 6))}
                                required
                                maxLength={4}
                            />
                            <Form.Text className="text-muted">
                                OTP has been sent to {email}
                            </Form.Text>
                        </Form.Group>

                        <div className="form-group mt-4">
                            <Button
                                variant="primary"
                                className="w-100"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? "Verifying..." : "Verify OTP"} <FontAwesomeIcon icon={faKey} />
                            </Button>
                        </div>

                        <div className="text-center mt-3">
                            <Button
                                variant="link"
                                onClick={handleResendOTP}
                                disabled={loading}
                            >
                                Resend OTP
                            </Button>
                        </div>

                        <div className="text-center mt-2">
                            <Button
                                variant="link"
                                onClick={() => {
                                    setStep("email");
                                    setOtp("");
                                    setError("");
                                }}
                            >
                                Change Email
                            </Button>
                        </div>
                    </Form>
                )}
            </Card.Body>
        </Card>
    );
}