import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Image, Modal, Row, Table } from 'react-bootstrap';
import { ClientService } from '../services/client.service';
import toast from 'react-hot-toast';
import CetLoader from '../Loader/CetLoader';

interface IPreviewModal {
    shift: any,
    show: any,
    handleClose: any
}

export default function CityPreviewModal({ shift, show, handleClose }: IPreviewModal) {

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);

    async function getDetails() {
        setLoading(true);
        await ClientService.getCityDetails(shift, show)
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data?.stats || []);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
                handleClose();
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (show !== "") {
            getDetails();
        }
    }, [show])


    // console.log(parseFloat("38,400"), "oparse Inr")



    return (
        <Modal
            show={show !== ""}
            onHide={handleClose}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Venue Wise Average Handling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt-3">
                    <Col>
                        <div className="d-flex flex-wrap gap-2 mt-3" style={{ overflowY: "auto" }}>
                            {loading ? (
                                <CetLoader />
                            ) : (
                                // <Card
                                //     style={{
                                //         backgroundColor: "#f9f9f9",
                                //         borderRadius: "12px",
                                //         border: "none",
                                //     }}
                                // >
                                //     <Card.Body>
                                        <Table striped bordered hover bgcolor="white">
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: 14 }}>#</th>
                                                    <th style={{ fontSize: 14 }}>Venue Name</th>
                                                    <th style={{ fontSize: 14 }}>Venue Id</th>
                                                    <th style={{ fontSize: 14 }}>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map((item: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ fontSize: 13 }}>{index + 1}</td>
                                                        <td style={{ fontSize: 13 }}>{item?.allocatedCenterId}</td>
                                                        <td style={{ fontSize: 13 }}>{item?.center}</td>
                                                        <td style={{ fontSize: 13 }}>{((item?.avgTimeInMin) * 60).toFixed(0)} sec(s)</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                //     </Card.Body>
                                // </Card>
                            )}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    )
}
