import React, { useEffect, useState } from 'react'
import AvarageTimingStats from '../Dashboard/AvarageTimingStats'
import Select from 'react-select';
import { Card, Col, Form, Row } from 'react-bootstrap'
import { ClientService } from '../../component/services/client.service';
import moment from 'moment';
import toast from 'react-hot-toast';

export default function CityWiseHandeling() {

    const [selectedValue, setSelectedValue] = useState<any>()
    const [shifts, setShifts] = useState<any>()
    // const [shiftDate, setShiftDate] = useState<any>();
    // const [selectedShiftDate, setSelectedShiftDate] = useState<any>()

    const [centers, setCenters] = useState<any>();
    const [allCenters, setAllCenters] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [isFilter, setIsFilter] = useState<boolean>(true);

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id,
                        startTime: data.startTime,
                        endTime: data.endTime
                    }
                }))
                // setShiftDate(res.data.shifts.map((data: any) => {
                //     return {
                //         label: moment(data.startTime).format("DD MMM YYYY"),
                //         value: data._id
                //     }
                // }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    useEffect(() => {
        getAllShift();
    }, []);

    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">District Wise Average Handling Time</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        // onChange={(e: any) => setSelectedShiftDate(e)} // Store full object
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectedValue(e?.value)}
                        isClearable
                        value={selectedValue ? shifts.find((shift: any) => shift.value === selectedValue.value) : null}
                    />
                </Col>
            </Row>
            {!selectedValue ?
                <p className="text-secondary fw-bold text-center mt-3">Please select shift first...</p>
                :
                <>
                    <div className="mt-3 mb-3">
                        <AvarageTimingStats
                            shift={selectedValue}
                        />
                    </div>
                </>
            }
        </>
    )
}