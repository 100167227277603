import { faUser, faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap'
import { UsersCard } from '../../component/Card/Statistics.card'
import Select from 'react-select'
import { ClientService } from '../../component/services/client.service'
import toast from 'react-hot-toast'
import VerifiedStats from './VerifiedStats'
import moment from 'moment'
import CetLoader from '../../component/Loader/CetLoader'
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import "../../component/Card/card.css"
import { colors } from 'react-select/dist/declarations/src/theme'

interface ICSRStats {
    shift: any;
    selectedCenters: any;
    selectedZones: any;
    selectedDistrict: any;
    selectedStates: any;
    states: any,
    cities: any,
    selectedCourses: any
}

export default function CsrStatsIndex(props: ICSRStats) {

    const [countData, setCountData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const [isActive, setIsActive] = useState<boolean>(false)

    const getCountData = async () => {
        const payload = {
            state: props.selectedStates?.map((data: any) => data?.value),
            city: props.selectedDistrict?.map((data: any) => data?.value),
            zone: props.selectedZones?.map((data: any) => data?.value),
            centerId: props.selectedCenters?.map((data: any) => data?.value),
            courseName: props.selectedCourses?.map((data: any) => data?.value),
        }
        setLoading(true);
        await ClientService.getCountInfo(props.shift, payload)
            .then((res) => {
                if (res.status === 200) {
                    setCountData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data);
            })
            .finally(() => setLoading(false));
    };

    const getGradientColor = (index: number) => {
        // Define gradient colors (modify as needed)
        const gradients = [
            "linear-gradient(to right, #ff7e5f, #feb47b)", // Orange-Pink
            "linear-gradient(to right, #6a11cb, #2575fc)", // Purple-Blue
            "linear-gradient(to right, #00b09b, #96c93d)", // Green-Yellow
            "linear-gradient(to right, #ff416c, #ff4b2b)", // Red-Orange
            "linear-gradient(to right, #36d1dc, #5b86e5)", // Cyan-Blue
        ];

        return gradients[index % gradients.length]; // Cycle through gradients
    };

    useEffect(() => {
        if (props.shift) {
            getCountData();
        }
    }, [props.shift, props.selectedCenters, props.selectedZones, props.selectedDistrict, props.selectedStates, props.selectedCourses]);

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#dcdcdc",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <div className="d-flex align-items-center  justify-content-end ">
                            <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={getCountData} />
                        </div>
                        <Card
                        >
                            <Card.Body>
                                {
                                    loading ? <CetLoader /> :
                                        <>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <h6>Statistics</h6>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col md={3}>
                                                    <UsersCard
                                                        reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                        header={"Schedule Candidates"}
                                                        statsValue={countData?.["Users Count"] || 0}
                                                        style={{ backgroundColor: "#11009E", color: "white" }}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <UsersCard
                                                        reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                        header={"Present Candidates"}
                                                        statsValue={countData?.["Biometric Count"] || 0}
                                                        style={{ backgroundColor: "#004A2F", color: "white" }}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <UsersCard
                                                        reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                        header={"Live Photo Capture"}
                                                        statsValue={countData?.["Biometric Count"] || 0}
                                                        style={{ backgroundColor: "#6C946F", color: "white" }}
                                                    />
                                                </Col>

                                                <Col md={3}>
                                                    <UsersCard
                                                        reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                        header={"Absent Candidates"}
                                                        statsValue={countData?.["Users Count"] - countData?.["Biometric Count"] || 0}
                                                        style={{ backgroundColor: "#6C946F", color: "white" }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {countData?.verification_data?.filter((data: any) => data.method !== "gps")?.map((data: any, index: number) => (
                                                    <Col md={3} key={index}>
                                                        <UsersCard
                                                            reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                            header={
                                                                data?.method === "fingerprint"
                                                                    ? "Right Fingerprint"
                                                                    : data?.method === "fingerprint2"
                                                                        ? "Left Fingerprint"
                                                                        : data?.method
                                                            }
                                                            statsValue={data?.total || 0}
                                                            style={{
                                                                backgroundColor: data?.method === "fingerprint"
                                                                    ? "#9B7EBD"
                                                                    : data?.method === "fingerprint2"
                                                                        ? "#7E60BF"
                                                                        : "#433878",
                                                                color: "white"
                                                            }}
                                                        />
                                                    </Col>
                                                ))}
                                                <Col md={3} className='d-flex justify-content-center align-items-center'>
                                                    {isActive ?
                                                        <FaCircleMinus className='d-flex justify-content-center align-items-center text-center xcn_plus_icon' size={50} onClick={() => setIsActive(false)} /> :
                                                        <FaCirclePlus className='d-flex justify-content-center align-items-center text-center xcn_plus_icon' size={50} onClick={() => setIsActive(true)} />
                                                    }
                                                </Col>

                                                {isActive &&
                                                    <>
                                                        {countData?.verification_data_exp?.filter((data: any) => data.method !== "gps")?.map((data: any, index: number) => (
                                                            <Col md={3} key={index}>
                                                                <UsersCard
                                                                    reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                                    header={
                                                                        data?.method === "fingerprint"
                                                                            ? "Right Fingerprint Exception"
                                                                            : data?.method === "fingerprint2"
                                                                                ? "Left Fingerprint Exception"
                                                                                : `${data?.method} Exception`
                                                                    }
                                                                    statsValue={data?.exceptionCount || 0}
                                                                    style={{
                                                                        backgroundColor: data?.method === "fingerprint"
                                                                            ? "#A888B5"
                                                                            : data?.method === "fingerprint2"
                                                                                ? "#8174A0"
                                                                                : "#441752",
                                                                        color: "white"
                                                                    }}
                                                                />
                                                            </Col>
                                                        ))}
                                                        <Col md={3}>
                                                            <UsersCard
                                                                reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                                                header={"Verified with Execption"}
                                                                statsValue={countData?.["By-Passed Total"] || 0}
                                                                style={{ backgroundColor: "#AED09E", color: "white" }}
                                                            />
                                                        </Col>

                                                    </>
                                                }
                                            </Row>

                                        </>
                                }

                            </Card.Body>
                        </Card>
                    </Card>
                </Col>
            </Row>
        </>
    )
}