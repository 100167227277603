import React, { useEffect, useState } from 'react';
import { ClientService } from '../../component/services/client.service';
import { Button, Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClock, faEye, faRefresh, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import CetLoader from '../../component/Loader/CetLoader';
import CityPreviewModal from '../../component/modals/CityPreview.modal';

interface IAvarageTimingStats {
    shift: any,
}

export default function AvarageTimingStats(props: IAvarageTimingStats) {
    const [averageTiming, setAverageTiming] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

    const getAverageTiming = async () => {
        setLoading(true);
        await ClientService.getCityStatsTime(props.shift).then((res) => {
            if (res.status === 200) {
                setAverageTiming(res.data.stats);
            }
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        if (props.shift) {
            getAverageTiming();
        }
    }, [props.shift]);

    // Sorting function
    const handleSortByTime = () => {
        const sortedData = [...averageTiming].sort((a, b) => {
            return sortOrder === "asc"
                ? a.avgTimeInMin - b.avgTimeInMin
                : b.avgTimeInMin - a.avgTimeInMin;
        });
        setAverageTiming(sortedData);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    return (
        <>
            <Row className="mt-3">
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#dcdcdc",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h6>District Wise Average Handling</h6>
                            </div>
                            <div className="d-flex flex-wrap gap-2 mt-3" style={{ overflowY: "auto" }}>
                                {loading ? (
                                    <CetLoader />
                                ) : (
                                    <Table striped bordered hover bgcolor="white">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>District</th>
                                                <th onClick={handleSortByTime} style={{ cursor: "pointer" }} className='d-flex align-items-center gap-3'>
                                                    Time{" "}
                                                    <FontAwesomeIcon icon={sortOrder === "asc" ? faSortUp : faSortDown} />
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {averageTiming.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.city}</td>
                                                    <td>{((data.avgTimeInMin) * 60).toFixed(0)} sec(s)</td>
                                                    <td>
                                                        <Button size="sm" onClick={() => setShow(data.city)}>
                                                            View <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <CityPreviewModal shift={props.shift} show={show} handleClose={() => setShow("")} />
        </>
    );
}
