import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClientService } from '../../component/services/client.service';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import TablePaginationComponent from '../../component/table/TablePagination';
import { makeParams } from '../../component/api/makeRequest';
import { FaArrowLeft } from 'react-icons/fa6';

export default function CenterStatsDetails() {

    const params = useParams();

    const [statsDetails, setStatsDetails] = useState<any>();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<number>(0);

    const getCenterStatsDetails = async () => {
        const qParams = makeParams([
            { index: 'pageNumber', value: pageNumber },
            { index: 'pageSize', value: pageSize }
        ]);
        await ClientService.getCenterStatsDetails(params.shiftId, params.centerId, qParams).then((res) => {
            if (res.status === 200) {
                setStatsDetails(res.data);
                setTotalCount(res.data.total || 0);
            }
        })
    }

    useEffect(() => {
        getCenterStatsDetails();
    }, [pageNumber, pageSize]);


    return (
        <>
            <div className="pt-4">
                <h4 className="d-flex text-secondary"> 
                    <span><FaArrowLeft onClick={() => window.history.back()} />&nbsp;</span>
                    Center Details
                </h4>
                <div>
                    <Row>

                        <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Scheduled Candidates"
                                statsValue={totalCount || 0}
                            />
                        </Col>
                        <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Present Candidates"
                                statsValue={statsDetails?.verified || 0}
                            />
                        </Col>
                        <Col md={4}>
                            <UsersCard
                                reactIcon={
                                    <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
                                }
                                header="Absent Candidates"
                                statsValue={
                                    totalCount && statsDetails?.verified
                                        ? totalCount - statsDetails?.verified
                                        : 0
                                }
                            />
                        </Col>
                    </Row>

                </div>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">Venue Id:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.centerPreference}</span>
                        </div>
                        {/* <div className="d-flex align-items-center">
                            <strong className="text-secondary">State:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.state}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">City:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.city}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <strong className="text-secondary">Division:</strong>
                            <span className="ms-2 text-dark">{statsDetails?.zone}</span>
                        </div> */}
                    </div>
                </Card>

                <h5 className="d-flex">Users</h5>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th style={{ fontSize: 14 }}>Sr. No</th>
                                <th style={{ fontSize: 14 }}>Name</th>
                                {/* <th style={{ fontSize: 14 }}>Email</th>
                                <th style={{ fontSize: 14 }}>Phone</th> */}
                                <th style={{ fontSize: 14 }}>Roll No</th>
                                {/* <th style={{ fontSize: 14 }}>Application Number</th> */}
                                <th style={{ fontSize: 14 }}>Verified</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statsDetails?.users?.map((data: any, index: any) => {
                                return (
                                    <tr>
                                        <td style={{ fontSize: 12 }}>{ pageNumber === 1 ? index + 1 : (pageNumber - 1) * pageSize + index + 1}</td>
                                        <td style={{ fontSize: 12 }}>{data?.name || "--"}</td>
                                        {/* <td style={{ fontSize: 12 }}>{data?.email || "--"}</td>
                                        <td style={{ fontSize: 12 }}>{data?.mobileNo || "--"}</td> */}
                                        <td style={{ fontSize: 12 }}>{data?.rollNo || "--"}</td>
                                        {/* <td style={{ fontSize: 12 }}>{data?.regNo || "--"}</td> */}
                                        <td style={{ fontSize: 12 }}>{data?.verificationId ? "Verified" : "Not-verified"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <Row className="mt-3">
                        <Col>
                            <TablePaginationComponent
                                currentPage={pageNumber}
                                itemsCount={totalCount}
                                itemsPerPage={pageSize}
                                setItemsPerPage={(e: any) => setPageSize(e)}
                                setCurrentPage={setPageNumber}
                            />
                        </Col>
                    </Row>
                </Card>
                {/* <h5 className="d-flex">Verified Users</h5>
                <Card className="shadow-sm mt-3 p-3 mb-3 bg-light rounded border-0">
                    <Table striped hover responsive>
                        <thead>
                        <tr>
                                <th style={{fontSize: 14}}>Sr. No</th>
                                <th style={{fontSize: 14}}>Name</th>
                                <th style={{fontSize: 14}}>Email</th>
                                <th style={{fontSize: 14}}>Phone</th>
                                <th style={{fontSize: 14}}>Roll No</th>
                                <th style={{fontSize: 14}}>Registration Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statsDetails?.verifiedUsers?.map((data: any, index: any) => {
                                return (
                                    <tr>
                                        <td style = {{fontSize: 12}}>{index + 1}</td>
                                        <td style = {{fontSize: 12}}>{data?.name || "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.email|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.mobileNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.rollNo|| "--"}</td>
                                        <td style = {{fontSize: 12}}>{data?.regNo|| "--"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card> */}

            </div>
        </>
    )
}
