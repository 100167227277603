import React from "react"

// Other Imports

// bootstrap
import { Button, Container, Modal } from "react-bootstrap";

// components

// Views
import LoginBox from './LoginBox/LoginBox';
// Icons

// CSS
import "./Login.css";
import LoginBoxOTP from "./LoginBox/LoginBoxOTP";


export default function Login() {
    const [show, setShow] = React.useState(false);
    return (
        <div className="bg-light-primary minh100">
            <Container fluid className="d-flex justify-content-center">
                {
                    !show ?
                        <LoginBox /> :
                        <LoginBoxOTP />
                }

            </Container>
                <div className="text-center mt-2">
                    <Button
                        variant="link"
                        onClick={() => {
                            setShow(!show);
                        }}
                    >
                        {
                            !show ?
                                "Login with Email OTP" :
                                "Login with username password"
                        }
                    </Button>
                </div>
        </div>
    )
}