import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from "react-select";
import { ClientService } from '../../component/services/client.service';
import { Card, Col, Row } from 'react-bootstrap';
import CetLoader from '../../component/Loader/CetLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

interface IVerificationTrends {
    shiftId: any;
    selectedCenters: any;
    selectedZones: any;
    selectedDistrict: any;
    selectedStates: any;
    defaultStartTime: any;
    defaultEndTime: any;
    selectedCourses: any;
}

export default function SlotChart(props: IVerificationTrends) {
    const [startTime, setStartTime] = useState<Date | null>(moment(props.defaultStartTime).subtract(1, 'hours').toDate());
    const [endTime, setEndTime] = useState<Date | null>(moment(props.defaultEndTime).add(1, 'hours').toDate());
    const [selectedTime, setSelectedTime] = useState<{ label: string; value: number } | null>(null);
    const [timelyLoading, setTimelyLoading] = useState<boolean>(false);
    const [verificationTrendsOptions, setVerificationTrendsOptions] = useState<any>(null);

    const time_interval = [
        { label: "15 min", value: 15 },
        { label: "30 min", value: 30 },
        { label: "45 min", value: 45 },
    ];

    const getVerificationTrendsCountStata = async () => {
        try {
            if (!selectedTime) return;

            const payload = {
                state: props.selectedStates?.map((data: any) => data?.value) || [],
                city: props.selectedDistrict?.map((data: any) => data?.value) || [],
                zone: props.selectedZones?.map((data: any) => data?.value) || [],
                centerId: props.selectedCenters?.map((data: any) => data?.value) || [],
                slotTime: selectedTime?.value,
                startDate: startTime ? startTime.toISOString() : null,
                endDate: endTime ? endTime.toISOString() : null,
                courseName: props.selectedCourses?.map((data: any) => data?.value),
            };

            setTimelyLoading(true);
            const res = await ClientService.getVerificationTrends(props.shiftId, payload);
            setVerificationTrendsOptions(res.data);
        } catch (error) {
            console.error("Failed to fetch verification count stats:", error);
        } finally {
            setTimelyLoading(false);
        }
    };

    useEffect(() => {
        getVerificationTrendsCountStata();
    }, [selectedTime, startTime, endTime, props.selectedCenters, props.selectedZones, props.selectedDistrict, props.selectedStates, props.selectedCourses]);

    useEffect(() => {
        setSelectedTime(time_interval[0]);
    }, []);

    return (


        <Card
            style={{
                backgroundColor: "#dcdcdc",
                borderRadius: "12px",
                border: "none",
            }}
            className='p-1 mb-2'
        >
            <div className="d-flex align-items-center  justify-content-end ">
                <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={getVerificationTrendsCountStata} />
            </div>
            <Row>
                <Col>
                    <Card
                        style={{
                            backgroundColor: "#f9f9f9",
                            borderRadius: "12px",
                            border: "none",
                        }}
                    >
                        {
                            timelyLoading ?
                                <CetLoader />
                                :
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>
                                            <h6>Timely Verification Trends</h6>
                                        </div>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="text-secondary" style={{ fontWeight: "500" }}>Start Time:</span>
                                                {/* <DatePicker
                                                    selected={startTime}
                                                    onChange={(date) => setStartTime(date)}
                                                    dateFormat="dd/MM/yy, h:mm aa"
                                                    timeFormat="HH:mm"
                                                    className="form-control"
                                                    placeholderText="Select Date and Time"
                                                    maxDate={new Date()}
                                                    minTime={startTime?.toISOString()}
                                                    maxTime={endTime}
                                                    showTimeSelect
                                                /> */}
                                                <DatePicker
                                                    selected={startTime}
                                                    onChange={(date) => setStartTime(date)}
                                                    dateFormat="dd/MM/yy, h:mm aa"
                                                    timeFormat="HH:mm"
                                                    className="form-control"
                                                    placeholderText="Select Date and Time"
                                                    maxDate={new Date()} 
                                                    minTime={moment(startTime).startOf('day').toDate()}
                                                    maxTime={moment(endTime).endOf('day').toDate()} 
                                                    showTimeSelect
                                                />
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="text-secondary" style={{ fontWeight: "500" }}>End Time:</span>
                                                <DatePicker
                                                    selected={endTime}
                                                    onChange={(date) => setEndTime(date)}
                                                    showTimeSelect
                                                    dateFormat="dd/MM/yy, h:mm aa"
                                                    timeFormat="HH:mm"
                                                    className="form-control"
                                                    placeholderText="Select Date and Time"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <Select
                                                options={time_interval}
                                                value={selectedTime}
                                                onChange={(e) => setSelectedTime(e)}
                                                isDisabled={timelyLoading}
                                            />
                                        </div>
                                    </div>

                                    <ResponsiveContainer width="100%" height={500}>
                                        <LineChart
                                            data={verificationTrendsOptions?.graph?.map((item: any) => ({
                                                ...item,
                                                Verifications: item.count,
                                            })) || []}
                                            margin={{ top: 20, right: 50, left: 40, bottom: 5 }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="slot" textAnchor="end" style={{ fontSize: 10 }} />
                                            <YAxis
                                                domain={[
                                                    0,
                                                    Math.max(verificationTrendsOptions?.expectedCount || 0,
                                                        ...(verificationTrendsOptions?.graph?.map((d: any) => d?.count) || [0]))
                                                ]}
                                            />
                                            <Tooltip />
                                            <Legend />
                                            {verificationTrendsOptions?.expectedCount && (
                                                <ReferenceLine
                                                    y={verificationTrendsOptions.expectedCount}
                                                    stroke="#004A2F"
                                                    strokeWidth={3}
                                                    label={{ position: 'right', value: 'Total', fill: '#004A2F' }}
                                                />
                                            )}
                                            <Line type="monotone" dataKey="Verifications" stroke="#dc7633" strokeWidth={3} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Card.Body>
                        }
                    </Card>
                </Col>
            </Row>
        </Card>
    );
}
