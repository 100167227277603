import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { AgCharts } from "ag-charts-react";
import { ClientService } from '../../component/services/client.service';
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import CetLoader from '../../component/Loader/CetLoader';

interface IStateStats {
    shiftId: any;
    cities: any;
}

export default function StateStats(props: IStateStats) {
    const [stateData, setStateData] = useState<any>();
    const [barChartOptions, setBarChartOptions] = useState<any>();
    const [selectedCity, setSelectedCity] = useState<any>(props.cities?.[0]);
    const [cityData, setCityData] = useState<any>();
    const [cityBarChartOptions, setCityBarChartOptions] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const getStatsByStateData = async () => {
        try {
            setLoading(true);
            const res = await ClientService.getStatsByStateData(props.shiftId);
            if (res.status === 200) {
                setStateData(res.data.stats);
                setBarChartOptions({
                    title: { text: "" },
                    data: res?.data?.stats,
                    series: [
                        // { type: "bar", xKey: "state", yKey: "total_students", yName: "Schedule", cornerRadius: 10, stacked: true, fill: "#004A2F" },
                        { type: "bar", xKey: "state", yKey: "bypassed", yName: "Present", cornerRadius: 10, stacked: true, fill: "#004A2F" },
                        { type: "bar", xKey: "state", yKey: "not_verified", yName: "Absent", cornerRadius: 10, stacked: true, fill: "#FFC785" },
                        // { type: "bar", xKey: "state", yKey: "additional_count", yName: "Additional Candidates", cornerRadius: 10, stacked: true, fill: "#80C4E9" },
                    ],
                    // fills: ["#008000", "#0000FF", "#FFA500", "#800080"],
                    fills: ["#FFC785", "#BAD8B6", "#8D77AB"],
                });
            }
        } catch (error) {
            console.error("Error fetching state data:", error);
        } finally {
            setLoading(false);
        }
    };

    const getStatsByCityData = async () => {
        if (!selectedCity) return;

        try {
            const res = await ClientService.getStatsByCityData(props.shiftId, selectedCity?.value);
            if (res.status === 200) {
                setCityData(res.data.stats);
                setCityBarChartOptions({
                    title: { text: "" },
                    data: res.data.stats,
                    series: [
                        { type: "bar", xKey: "city", yKey: "total_students", yName: "Total", cornerRadius: 10, fill: "#FF7F3E" },
                        { type: "bar", xKey: "city", yKey: "not_verified", yName: "Absent", cornerRadius: 10, fill: "#FFC785" },
                        { type: "bar", xKey: "city", yKey: "total_verification", yName: "Present", cornerRadius: 10, fill: "#004A2F" },
                        { type: "bar", xKey: "city", yKey: "bypassed", yName: "Present with Exceptions", cornerRadius: 10, fill: "#AED09E" },
                        // { type: "bar", xKey: "city", yKey: "additional_count", yName: "Additional Candidates", cornerRadius: 10, fill: "#80C4E9" },
                    ],
                });
            }
        } catch (error) {
            console.error("Error fetching city data:", error);
        }
    };

    useEffect(() => {
        if (props.shiftId) {
            getStatsByStateData();
        }
    }, [props.shiftId]);

    useEffect(() => {
        if (props.shiftId && selectedCity) {
            getStatsByCityData();
        }
    }, [props.shiftId, selectedCity]);

    return (
        <>
            <Card
                style={{
                    backgroundColor: "#dcdcdc",
                    borderRadius: "12px",
                    border: "none",
                }}
                className='p-1'
            >
                <div className="d-flex align-items-center  justify-content-end ">
                    <FontAwesomeIcon icon={faRefresh} className='m-2 cursor-pointer' onClick={() => getStatsByStateData()} />
                </div>
                <Row className="mt-3">
                    <Col>
                        <Card style={{ backgroundColor: "#f9f9f9", borderRadius: "12px", border: "none" }}>
                            {
                                loading ?
                                    <CetLoader />
                                    :
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h6>State Wise Statistics</h6>
                                        </div>
                                        {stateData ? (
                                            <AgCharts options={barChartOptions} style={{ height: 340 }} />
                                        ) : (
                                            <p>Loading state data...</p>
                                        )}
                                    </Card.Body>
                            }
                        </Card>
                    </Col>
                </Row>
                {props.cities && props.cities.length > 0 &&
                    <Row className="mt-3">
                        <Col>
                            <Card style={{ backgroundColor: "#f9f9f9", borderRadius: "12px", border: "none" }}>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h6>District Wise Statistics</h6>
                                        <Select
                                            options={props.cities}
                                            value={selectedCity}
                                            onChange={(e: any) => setSelectedCity(e)}
                                        />
                                    </div>
                                    {cityData ? (
                                        <AgCharts options={cityBarChartOptions} style={{ height: 340 }} />
                                    ) : (
                                        <p>Loading city data...</p>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </Card>
        </>
    );
}
