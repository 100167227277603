import React, { useEffect, useState } from "react";
import { Table, Spinner, Container, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { FaRegEye } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

interface Props {
    data: any;
    pageNumber: number;
    pageSize: number;
}

const DataTable: React.FC<Props> = ({ data, pageNumber, pageSize }) => {
    const [tableData, setTableData] = useState<any>([]);
    function deviceInfo(deviceDataString: string) {
        let deviceData = JSON.parse(deviceDataString);
        const deviceTypeMap = deviceData?.DeviceType || {};
        const deviceName = deviceData?.deviceName || "Unknown Device";
        const deviceType = deviceTypeMap?.[deviceData?.deviceType] || "UNKNOWN";
        return deviceType + " - " + deviceName;
    }

    function handleSort() {
        if (tableData?.length < 2) return;

        let sampleSize = Math.min(3, tableData?.length - 1);
        let isAscending = true;

        for (let i = 0; i < sampleSize; i++) {
            if (parseFloat(tableData?.[i]?.meta?.battery?.percentage) > parseFloat(tableData?.[i + 1]?.meta?.battery?.percentage)) {
                isAscending = false;
            }
        }

        let sorted = [...tableData].sort((a, b) => {
            const percentA = parseFloat(a?.meta?.battery?.percentage || 0);
            const percentB = parseFloat(b?.meta?.battery?.percentage || 0);
            return isAscending ? percentB - percentA : percentA - percentB;
        });

        setTableData(sorted);
    }

    useEffect(() => {
        setTableData(data);
    }, [data]);

    return (
        <>

            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th style={{ fontSize: 14 }}>#</th>
                                <th style={{ fontSize: 14 }}>Venue ID</th>
                                <th style={{ fontSize: 14 }}>User Name</th>
                                <th style={{ fontSize: 14 }}>Operator ID</th>
                                <th style={{ fontSize: 14 }}>Operator Username</th>
                                {/* <th style={{ fontSize: 14 }}>Email</th> */}
                                <th style={{ fontSize: 14 }}>Last Synced</th>
                                <th style={{ fontSize: 14 }}>Last Download</th>
                                <th style={{ fontSize: 14 }}>Download %</th>
                                <th style={{ fontSize: 14 }}>Battery % <FontAwesomeIcon icon={faSort} className='cursor-pointer' onClick={handleSort} /></th>
                                {/* <th style={{ fontSize: 14 }}>Device Info</th> */}
                                {/* <th style={{ fontSize: 14 }}>Upload %</th> */}
                                <th style={{ fontSize: 14 }}>First Login</th>
                                <th style={{ fontSize: 14 }}>Last Login</th>
                                <th style={{ fontSize: 14 }}>Last Login Build Version</th>
                                {/* <th style={{ fontSize: 14 }}>Last Logout</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.length > 0 ? (
                                tableData.map((item: any, index: number) => (
                                    <tr key={item?._id}>
                                        <td style={{ fontSize: 12 }}>{index + 1 + (pageNumber - 1) * pageSize}</td>
                                        <td style={{ fontSize: 12 }}>{item?.centerId?.[0]}</td>
                                        <td style={{ fontSize: 12 }}>{item?.userName}</td>
                                        <td style={{ fontSize: 12 }}>{item?.operatorId}</td>
                                        <td style={{ fontSize: 12 }}>{item?.operatorName}</td>
                                        {/* <td style={{ fontSize: 12 }}>{item?.emailId}</td> */}
                                        {/* <td>{item?.examId}</td>
                                    <td>{item?.shiftId}</td> */}
                                        <td style={{ fontSize: 12 }}>
                                            {moment(item?.lastSyncedOn).isValid()
                                                ? moment(item?.lastSyncedOn).format("DD-MM-YYYY hh:mm A")
                                                : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {moment(item?.lastDownloadAt).isValid()
                                                ? moment(item?.lastDownloadAt).format("DD-MM-YYYY hh:mm A")
                                                : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {item?.meta?.downloadStats?.percentage >= 0
                                                ?
                                                <div className="text-nowrap">
                                                    <span>{item?.meta?.downloadStats?.percentage}%</span><br />
                                                    {/* <span className="fs-12">Download on: <br /> {moment(item?.meta?.downloadStats?.syncedOn).format("DD-MM-YYYY hh:mm A")}</span> */}
                                                </div>
                                                : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {item?.meta?.battery?.percentage >= 0
                                                ?
                                                <div className="text-nowrap">
                                                    <span>{item?.meta?.battery?.percentage?.toFixed(2)}%</span><br />
                                                    <span className="fs-12">Updated At: <br /> {moment(item?.meta?.battery?.updatedAt).format("DD-MM-YYYY hh:mm A")}</span>
                                                </div>
                                                : "N/A"}
                                        </td>
                                        {/* <td style={{ fontSize: 12 }}>
                                            {item?.meta?.devices && item?.meta?.devices?.length > 0 ? (
                                                <div className="text-nowrap" style={{ display: "flex", alignItems: "center" }}>
                                                    <span>{deviceInfo(item?.meta?.devices?.[0]?.dev_info)}</span>
                                                    {item?.meta?.devices?.length > 1 && (
                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id="tooltip-multiple-devices">
                                                                    {item?.meta?.devices?.slice(1).map((device: any, index: number) => (
                                                                        <div key={index}>{deviceInfo(device?.dev_info)}</div>
                                                                    ))}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span style={{ marginLeft: 8, cursor: "pointer" }}>
                                                                <FaRegEye />
                                                            </span>
                                                        </OverlayTrigger>
                                                    )}
                                                </div>
                                            ) : (
                                                "N/A"
                                            )}
                                        </td> */}
                                        {/* <td style={{ fontSize: 12 }}>
                                            {item?.meta?.uploadStats.percentage >= 0
                                                ?
                                                <div className="text-nowrap">
                                                    <span>{item?.meta?.uploadStats?.percentage}%</span><br />
                                                    <span className="fs-12">Synced on: <br /> {moment(item?.meta?.uploadStats?.syncedOn).format("DD-MM-YYYY hh:mm A")}</span>
                                                </div>
                                                : "N/A"}
                                        </td> */}
                                        <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.loginTimes?.length > 0
                                                    ? moment(item?.meta?.loginTimes?.[0]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td>
                                        <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.loginTimes?.length > 0
                                                    ? moment(item?.meta?.loginTimes?.[item?.meta?.loginTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td>
                                        {/* <td style={{ fontSize: 12 }}>
                                            {
                                                item?.meta?.logoutTimes?.length > 0
                                                    ? moment(item?.meta?.logoutTimes?.[item?.meta?.logoutTimes?.length - 1]).format("DD-MM-YYYY hh:mm A")
                                                    : "N/A"}
                                        </td> */}
                                        <td style={{ fontSize: 12 }}>
                                            {item?.meta?.devices && item?.meta?.devices?.length > 0 ? (
                                                <div className="text-nowrap" style={{ display: "flex", alignItems: "center" }}>
                                                    <span>{item?.meta?.devices?.[item?.meta?.devices?.length - 1]?.version}</span>
                                                </div>
                                            ) : (
                                                "N/A"
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={12} className="text-center">
                                        No Data Available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
};

export default DataTable;
