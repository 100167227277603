import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Pagination, Row, Spinner, Table } from 'react-bootstrap'
import toast from 'react-hot-toast';
import Select from "react-select"
import { UsersCard } from '../../component/Card/Statistics.card';
import { ClientService } from '../../component/services/client.service';
import CsrReportTable from '../../component/table/CsrReportTable';
import downloadjs from 'downloadjs';
import { faCheckCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import UserModal from '../../component/modals/User.modal';
import { FaRegEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';
import CetLoader from '../../component/Loader/CetLoader';
import { AgCharts } from 'ag-charts-react';
import TablePaginationComponent from '../../component/table/TablePagination';

export default function CenterCount() {

    const navigate = useNavigate();


    const [selectValue, setSelectValue] = useState<any>();
    const [shifts, setShifts] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [centerData, setCenterData] = useState<any>([])

    const [zones, setZones] = useState<any>();
    const [districts, setDistricts] = useState<any>();
    const [centers, setCenters] = useState<any>();
    const [states, setStates] = useState<any>();


    const [selectedZones, setSelectedZones] = useState<any>();
    const [selectedDistricts, setSelectedDistricts] = useState<any>();
    const [selectedCenters, setSelectedCenters] = useState<any>();
    const [selectedStates, setSelectedStates] = useState<any>();

    const [shiftDate, setShiftDate] = useState<any>();
    const [selectedShiftDate, setSelectedShiftDate] = useState<any>();
    const [barChartOptions, setBarChartOptions] = useState<any>();
    const [isFilter, setIsFilter] = useState<boolean>(true);
    const [allCenters, setAllCenters] = useState<any>();


    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<number>(0);



    const getAllZones = async () => {
        await ClientService.getUniqueZone(selectValue).then(res => {
            if (res.status === 200) {
                setZones(res.data.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCenter = async () => {
        let params = new URLSearchParams();
        params.append("zone[]", selectedZones?.value);
        params.append("state[]", selectedStates?.value);
        params.append("district[]", selectedDistricts?.value);

        await ClientService.getUniqueCenter2(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setCenters(
                    res.data?.centers.map((zone: any) => ({
                        value: zone?.centerPreference,
                        label: zone?.allotedCenterId + " - " + zone?.centerPreference,
                    }))
                );
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getAllCenters = async () => {
        await ClientService.getUniqueCenter2(selectValue).then(res => {
            if (res.status === 200) {
                setAllCenters(
                    res.data?.centers.map((zone: any) => ({
                        value: zone?.centerPreference,
                        label: zone?.allotedCenterId + " - " + zone?.centerPreference,
                    }))
                )
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueState = async () => {
        const params = new URLSearchParams();
        selectedZones.forEach((zone: any) => params.append("zone[]", zone?.value));
        await ClientService.getStates(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setStates(res.data.states?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getUniqueCity = async () => {
        let params = new URLSearchParams();
        params.append("zone[]", selectedZones?.value);
        params.append("state[]", selectedStates?.value);
        await ClientService.getCities(selectValue, "?" + params.toString()).then(res => {
            if (res.status === 200) {
                setDistricts(res.data?.districts?.map((zone: any) => {
                    return {
                        value: zone,
                        label: zone
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }


    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getCenterStats = async () => {
        setLoading(true)
        await ClientService.getCenterStats(selectValue, selectedCenters?.value, selectedZones?.value, selectedStates?.value, selectedDistricts?.value, pageNumber, pageSize)
            .then((res) => {
                if (res.status === 200) {
                    setCenterData(res.data.centerStats);
                    setTotalCount(res.data.totalCenters);
                    let chartData = res?.data?.centerStats?.map((data: any) => {
                        return {
                            label: data.centerPreference,
                            value: parseInt(data?.verificationPercentage)
                        }
                    })
                    setBarChartOptions({
                        title: { text: "" },
                        data: chartData,
                        series: [
                            {
                                type: "bar",
                                xKey: "label",
                                yKey: "value",
                                yName: "Verification (%)",
                                cornerRadius: 10,
                                fill: "#004A2F"
                            },
                        ],
                        axes: [
                            {
                                type: "category",
                                position: "bottom",
                                title: {
                                    text: "Center Name",
                                },
                            },
                            {
                                type: "number",
                                position: "left",
                                max: 100,
                                min: 0,
                                title: {
                                    text: "Verification (%)",
                                },
                            },
                        ],
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response?.data?.message || "An error occurred");
            });
        setLoading(false)
    };

    async function downloadCsv() {
        if (!centerData?.length) return toast.error("No data found");
        const csvData = [
            'Center,Zone,State,City,Enrolled,Verified',
            ...centerData.map((center: any) =>
                `${center.centerPreference},${center.zone},${center.state},${center.city},${center.totalUsers},${center.verifiedUsers}`
            ),
        ].join('\n');
        saveAsCSV(csvData, 'center-stats-data');
    }

    function handleSort() {
        if (centerData?.length < 2) return;

        let sampleSize = Math.min(3, centerData?.length - 1);
        let isAscending = true;

        for (let i = 0; i < sampleSize; i++) {
            if (parseFloat(centerData?.[i]?.verificationPercentage) > parseFloat(centerData?.[i + 1]?.verificationPercentage)) {
                isAscending = false;
            }
        }

        let sorted = [...centerData].sort((a, b) => {
            const percentA = parseFloat(a.verificationPercentage);
            const percentB = parseFloat(b.verificationPercentage);

            return isAscending ? percentB - percentA : percentA - percentB;
        });

        setCenterData(sorted);
    }

    useEffect(() => {
        if (selectValue) {
            getUniqueState();
            getAllCenters();
        }
    }, [selectValue]);

    useEffect(() => {
        if (selectedStates) {
            getAllZones();
        } else {
            setZones([]);
            setSelectedZones([]);
        }
    }, [selectedStates]);

    useEffect(() => {
        if (selectedStates && selectedZones) {
            getUniqueCity();
        } else {
            setDistricts([]);
            setSelectedDistricts(null);
        }
    }, [selectedZones]);

    useEffect(() => {
        if (selectedStates && selectedZones && selectedDistricts) {
            getUniqueCenter();
        } else {
            setCenters([]);
            setSelectedCenters(null);
        }
    }, [selectedDistricts]);

    useEffect(() => {
        getAllShift();
    }, [])

    useEffect(() => {
        if (selectValue) {
            getCenterStats();
        }
    }, [selectedCenters, selectedDistricts, selectedStates, selectedZones, selectValue, pageNumber, pageSize])

    return (
        <>
            <Row className="mt-5 text-left">
                <Col md={8}>
                    <h4 className="text-secondary mt-3 fw-bold">Venue Stats</h4>
                </Col>
                <Col md={4}>
                    <Form.Label className="text-muted fw-bold">
                        Select Shift
                    </Form.Label>
                    <Select
                        options={shifts}
                        placeholder="Select Shifts"
                        onChange={(e: any) => setSelectValue(e?.value)}
                        isClearable
                        value={selectValue ? shifts.find((shift: any) => shift.value === selectValue.value) : null}
                    />
                </Col>
            </Row>


            <Card
                className="mt-3"
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                    border: "none",
                }}
            >
                <Card.Body>
                    <Form.Switch
                        id="custom-switch"
                        label="Show Filter"
                        checked={isFilter}
                        onChange={(e: any) => { setIsFilter(e.target.checked); setSelectedCenters([]); setSelectedStates([]); setSelectedDistricts([]); setSelectedZones([]); }}
                    />
                    {
                        isFilter ?
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            States
                                        </Form.Label>
                                        <Select
                                            options={states}
                                            onChange={(e: any) => setSelectedStates(e)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                            value={selectedStates}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Divisions
                                        </Form.Label>
                                        <Select
                                            options={zones}
                                            onChange={(e: any) => setSelectedZones(e)}
                                            value={selectedZones}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            District
                                        </Form.Label>
                                        <Select
                                            options={districts}
                                            onChange={(e: any) => setSelectedDistricts(e)}
                                            value={selectedDistricts}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Venue
                                        </Form.Label>
                                        <Select
                                            options={centers}
                                            onChange={(e: any) => setSelectedCenters(e)}
                                            value={selectedCenters}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isClearable
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label className="text-muted fw-bold">
                                            Search Venue
                                        </Form.Label>
                                        <Select
                                            options={allCenters}
                                            onChange={(selectedOptions: any) => setSelectedCenters(selectedOptions)}
                                            value={selectedCenters}
                                            isClearable
                                            isMulti
                                            isSearchable
                                            placeholder="Type to search venue"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                    }
                </Card.Body>
            </Card>
            {loading ? <CetLoader /> :
                <Row>
                    {/* <div className="d-flex align-items-center justify-content-end mb-2 mt-3">
                        <h4 className="d-flex text-secondary">Center Stats</h4>
                        <Button className="ms-2" size="sm" onClick={downloadCsv}>Download</Button>
                    </div> */}
                    {/* {
                        centerData && centerData.length > 0 &&
                        <Col md={12}>
                            <Card
                                className="mt-3"
                                style={{
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "12px",
                                    border: "none",
                                }}
                            >
                                <Card.Body>
                                    <AgCharts options={barChartOptions} style={{ height: 340 }} />
                                </Card.Body>
                            </Card>
                        </Col>
                    } */}

                    <Row className='mt-2'>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button size="sm" onClick={getCenterStats}>
                                <FontAwesomeIcon icon={faRefresh} /> Reload
                            </Button>
                        </div>
                    </Row>
                    <Row className='mt-2'>
                        <Col md={3}>
                            <UsersCard
                                reactIcon={<FontAwesomeIcon icon={faCheckCircle} className="fs-1 text-muted" />}
                                header={"Total Venues"}
                                statsValue={totalCount}
                                style={{ backgroundColor: "#004A2F", color: "white" }}
                            />
                        </Col>
                    </Row>

                    <Col>
                        <Card
                            className="mt-3"
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                border: "none",
                            }}
                        >
                            <Card.Body>
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: 14 }}>Sr. No</th>
                                            <th style={{ fontSize: 14 }}>Venue ID</th>
                                            <th style={{ fontSize: 14 }}>Venue Name</th>
                                            <th style={{ fontSize: 14 }}>Division</th>
                                            <th style={{ fontSize: 14 }}>State</th>
                                            <th style={{ fontSize: 14 }}>City</th>
                                            <th style={{ fontSize: 14 }}>Scheduled Candidates</th>
                                            <th style={{ fontSize: 14 }}>Present Candidates</th>
                                            <th style={{ fontSize: 14 }}>Present (%)</th>
                                            <th style={{ fontSize: 14 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {centerData?.map((data: any, index: any) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1 + (pageNumber - 1) * pageSize}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.centerPreference}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.allocatedCenterId}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.zone}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.state}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.city}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.totalUsers}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.uniqueVerifiedUsers}</td>
                                                    <td style={{ fontSize: 12 }}>{data?.verificationPercentage}%</td>
                                                    <td style={{ fontSize: 12 }}><FaRegEye className='text-primary' onClick={() => navigate("/center-stats/" + selectValue + '/' + data?.centerPreference)} /> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Row className='mt-2'>
                        <Col>
                            <TablePaginationComponent
                                currentPage={pageNumber}
                                itemsCount={totalCount}
                                itemsPerPage={pageSize}
                                setItemsPerPage={(e: any) => setPageSize(e)}
                                setCurrentPage={setPageNumber}
                            />
                        </Col>
                    </Row>
                </Row>
            }
        </>
    )
}