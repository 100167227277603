import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { UsersCard } from '../../component/Card/Statistics.card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUser } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { ClientService } from '../../component/services/client.service';
import toast from 'react-hot-toast';
import { makeParams } from '../../component/api/makeRequest';
import DataTable from '../../component/table/Invigilator.table';
import { saveAsCSV } from '../../component/helpers/csvhelper';
import moment from 'moment';
import TablePaginationComponent from '../../component/table/TablePagination';

export default function InvigilatorStatsIndex() {
    const [statsData, setStatsData] = useState<any>(null);
    const [centers, setCenters] = useState<any>([]);
    const [selectedCenters, setSelectedCenters] = useState<any>([]);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);


    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<any>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [key, setKey] = useState<any>('completed');


    const getUniqueCenter = async () => {
        try {
            const res = await ClientService.getUniqueCenter2(selectedShift);
            if (res.status === 200) {
                setCenters(
                    res.data?.centers.map((zone: any) => ({
                        value: zone?.centerPreference,
                        label: zone?.allotedCenterId + " - " + zone?.centerPreference,
                    }))
                );
            }
        } catch (err: any) {
            toast.error(err.response?.data || 'Failed to fetch centers');
        }
    };

    const getAllShift = async () => {
        await ClientService.getAllShifts().then(res => {
            if (res.status === 200) {
                setShifts(res.data.shifts.map((data: any) => {
                    return {
                        label: data.shiftName + " - " + moment(data.startTime).format("DD MMM YYYY"),
                        value: data._id
                    }
                }))
            }
        }).catch(err => {
            toast.error(err.response.data);
        })
    }

    const getStats = async () => {
        const payload = {
            centerId: selectedCenters?.map((data: any) => data?.value),
        }
        setLoading(true);
        try {
            const res = await ClientService.getOperatorStats(selectedShift, key, pageNumber, pageSize, payload);
            if (res.status === 200) {
                setStatsData(res.data.invigilators);
                setTotalCount(res.data.total);
            }
        } catch (err: any) {
            toast.error(err.response?.data || 'Failed to fetch stats');
        } finally {
            setLoading(false);
        }
    };

    const tabOptions = [
        { label: "Download Completed", value: "completed", iconClass: "fs-1 text-success" },
        { label: "Download Pending", value: "pending", iconClass: "fs-1 text-warning" },
        { label: "Logged-In Biometric Operators", value: "loggedin", iconClass: "fs-1 text-success" },
        { label: "Total Biometric Operators", value: "total", iconClass: "fs-1" },
    ];

    const downloadCsv = (data: any[], fileName: string = 'data') => {
        if (!data?.length) {
            toast.error('No data found');
            return;
        }

        const csvData = [
            'Center,User Name,Email,Last Sync Time,Last Download Time,Download %,Upload %,Last Login Time,Last Logout Time',
            ...data.map((item: any) =>
                `${item?.centerId?.[0]},${item?.userName},${item?.emailId},${moment(item?.lastSyncedOn).format('DD-MM-YYYY hh:mm A')},${moment(item?.lastDownloadAt).format('DD-MM-YYYY hh:mm A')},${item?.meta.downloadStats.percentage},${item?.meta.uploadStats.percentage},${moment(item?.meta?.loginTimes?.slice(-1)?.[0]).format('DD-MM-YYYY hh:mm A')},${moment(item?.meta?.logoutTimes?.slice(-1)?.[0]).format('DD-MM-YYYY hh:mm A')}`
            ),
        ].join('\n');

        saveAsCSV(csvData, fileName);
    };

    useEffect(() => {
        if (selectedShift) {
            getStats();
        }
    }, [selectedCenters, selectedShift, key, pageNumber, pageSize]);

    useEffect(() => {
        if (selectedShift) {
            getUniqueCenter();
        }
    }, [selectedShift]);

    useEffect(() => {
        getAllShift();
    }, []);

    return (
        <>
            <>
                <Row className="mt-5 text-left">
                    <Col md={8}>
                        <h4 className="text-secondary">Biometric Operators Stats</h4>
                    </Col>
                    <Col md={4}>
                        <Form.Label className="text-muted fw-bold">
                            Select Shift
                        </Form.Label>
                        <Select
                            options={shifts}
                            placeholder="Select Shifts"
                            onChange={(e: any) => setSelectedShift(e?.value)}
                            isClearable
                            value={selectedShift ? shifts.find((shift: any) => shift.value === selectedShift.value) : null}
                        />
                    </Col>
                </Row>
                <Card className="mt-3" style={{ backgroundColor: '#f9f9f9', borderRadius: '12px', border: 'none' }}>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label className="text-muted fw-bold">Venue</Form.Label>
                                    <Select
                                        options={[{ label: 'All', value: 'All' }, ...(centers || [])]}
                                        onChange={(selectedOptions: any) => {
                                            if (selectedOptions?.some((option: any) => option.value === 'All')) {
                                                setSelectedCenters(centers);
                                            } else {
                                                setSelectedCenters(selectedOptions || []);
                                            }
                                        }}
                                        value={selectedCenters}
                                        isClearable
                                        isMulti
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {loading &&
                    <div className="d-flex justify-content-center align-items-center my-3">
                        <Spinner animation="border" />
                    </div>
                }
                <Row className="mt-4">
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        {tabOptions.map(({ label, value, iconClass, index }: any) => (
                            <Tab eventKey={value} title={label} key={value}>
                                <div className="mt-2">
                                    <UsersCard
                                        reactIcon={<FontAwesomeIcon icon={faUser} className={iconClass} />}
                                        header="Count"
                                        statsValue={totalCount || 0}
                                    />
                                </div>
                                <div>
                                    <DataTable data={statsData}  pageNumber={pageNumber} pageSize={pageSize} />
                                </div>
                                <Row className='mt-2'>
                                    <Col>
                                        <TablePaginationComponent
                                            currentPage={pageNumber}
                                            itemsCount={totalCount}
                                            itemsPerPage={pageSize}
                                            setItemsPerPage={(e: any) => setPageSize(e)}
                                            setCurrentPage={setPageNumber}
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                        ))}
                    </Tabs>
                </Row>
            </>
            {/* )} */}
        </>
    );
}
