const url = {
    login: "/client/clientLogin",
    // getAllShifts: "/client/getAllExamShift",
    getAllShifts: "/client/getAllLiveExamShift",
    getVerificationEnums: "/client/getVerificationEnums",
    sendOtp:'/client/send-otp',
    verifyOtp:'/client/verify-otp',


    stats: {
        getCountInfo: "/client/countInfo",
        getInvigilatorStats: "/client/stats",
        getOperatorStats: "/client/inv/stats/data",
        getVerifiedCount: "/client/verifiedCountUsers",
        getVerificationCountStats: "/client/getVerificationCountStats",
        getVerificationByState: "/client/getVerificationByState",
        // getVerifiedUserCount: "client/verifiedCountUsers",
    },

    client: {
        getVerificationData: "/client/getVerification",
        getUniqueCity: "/client/uniqueCities",
        getUniqueState: "/client/uniqueStates",
        getUniquesZone: "/client/uniqueZones",
        getUniqueCenter: "/client/uniqueCenters",
        getUniqueCenter2: "/client/dropdown/center/name",
        getUsers: "/client/getUsers",
        getUserData: "/client/getUsersData",
        downloadVerifiedZip: "/client/verifiedZip",
        searchByRollNo: "/client/user/search",
        getDatabyUserId: "/client/getDatabyUserId",
        getCenterCount: "/client/centerCount",
        getCenterStats: "/client/centerStats",
        centerStatsDetails: "/client/centerStatsDetails",
        getAllCSRReport: "/client/CSRReports",
        getAllBypassData: "/client/user/bypass",
        getDropdownData: "/client/dropdown",
        getInvigilators: "/client/invigilators",
        getStatsByStateData: "/client/dashboard/stats/state",
        getCityStatsTime: "/client/dashboard/stats/time/city",
        getStatsByCityData: "/client/dashboard/stats/city",

        getVerificationTrends: "/client/stats/verification/trends",
        getCityDetails: "/client/dashboard/stats/time/center",

        downloadShiftReport: "/client/export/shift/csv",
        downloadShiftVerificationData: "/client/export/verfications",
        getShiftRequestData: "/client/export/requests",
        updateRequestCount: "/client/export/update-count",

        getExceptionReport: "/client/exception/get"
    },

    dropdown: {
        getZones: '/client/dropdown/zones',
        getStates: '/client/dropdown/states',
        getCities: '/client/dropdown/district',
        getCenters: '/client/dropdown/center',
        getUniqueCourses: '/client/dropdown-courses'

    }

};

export default url;
