import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, ProgressBar, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { ClientService } from '../../component/services/client.service';
import moment from 'moment';
import Select from 'react-select';
import { IoCloudDownloadOutline } from "react-icons/io5";
import { FaBoxOpen, FaRulerCombined, FaWeightHanging } from 'react-icons/fa6';
import { LuCalendarClock } from "react-icons/lu";
import { BsClockFill } from "react-icons/bs";
import { request } from 'http';

export const ShiftReport = () => {

    const [selectedValue, setSelectedValue] = useState<any | null>(null);
    const [shifts, setShifts] = useState<{ label: string, value: string, startTime: string, endTime: string }[]>([]);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [requestedData, setRequestedData] = useState<any[]>([]);
    const [downloadType, setDownloadType] = useState<string>('');


    const handleDownloadShiftReport = async (dType = "") => {
        setLoading(true)
        if (dType === 'verification') {
            await ClientService.downloadShiftVerificationData(selectedValue?.value, email)
                .then((res) => {
                    getShiftRequestData();
                    setShowReportModal(false);
                    setDownloadType('');
                }
                )
                .catch((err) => {
                    console.log(err);
                }
                ).finally(() => {
                    setLoading(false);
                });

        } else {
            await ClientService.downloadShiftReport(selectedValue?.value, email)
                .then((res) => {
                    if (res.status === 200) {
                        getShiftRequestData();
                        setShowReportModal(false)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const getShiftRequestData = async () => {
        await ClientService.getShiftRequestData(selectedValue?.value)
            .then((res) => {
                if (res.status === 200) {
                    setRequestedData(res.data.exports);
                    console.log(res.data.exports , 'set data')
                }
            })
            .catch((err) => {
                console.log(err);
            }
            );
    }

    const getAllShift = async () => {
        try {
            const res = await ClientService.getAllShifts();
            if (res.status === 200 && res.data.shifts) {
                const formattedShifts = res.data.shifts.map((data: any) => ({
                    label: `${data.shiftName} - ${moment(data.startTime).format("DD MMM YYYY")}`,
                    value: data._id,
                    startTime: data.startTime,
                    endTime: data.endTime
                }));
                setShifts(formattedShifts);
            }
        } catch (err: any) {
            toast.error(err?.response?.data || "Failed to fetch shifts");
        }
    };

    const handleUpdateRequestCount = async (exportId: string, url: string) => {
        try {
            await ClientService.updateRequestCount(exportId); // Ensure request count is updated
            window.open(url, "_blank"); // Open the link in a new tab
        } catch (err) {
            console.error("Error updating request count:", err);
        }
    };
    useEffect(() => {
        if (selectedValue) {
            getShiftRequestData();
        }
    }, [selectedValue]);

    useEffect(() => {
        getAllShift();
    }, []);

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mt-5'>
                <div>
                    <h4 className="text-secondary mt-3 fw-bold">Download Shift Report</h4>
                </div>
                <div className='d-flex align-items-center gap-3'>
                    <div style={{ width: 300 }}>
                        <Form.Group className='mb-3'>
                            <Select
                                options={shifts}
                                value={selectedValue}
                                onChange={(selected) => setSelectedValue(selected)}
                                placeholder="Select a Shift"
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
            <div className="mt-3">
                {selectedValue &&
                    <div>
                        <div className="my-3 d-flex justify-content-end align-items-center gap-2">
                            {/* <Button size="sm" onClick={() => setShowReportModal(true)}>
                                <IoCloudDownloadOutline size={18} className="me-2" />
                                Download Image Data
                            </Button> */}
                            <Button
                                size="sm"
                                variant="outline-secondary"
                                onClick={() => handleDownloadShiftReport("")}
                                disabled={
                                    requestedData?.length
                                        ? requestedData[requestedData.length - 1]?.progress !== 100
                                        : false
                                }
                            >
                                <IoCloudDownloadOutline size={18} className="me-2" />
                                Download CSV Data
                            </Button>
                            {/* <Button
                                size="sm"
                                variant="outline-secondary"
                                onClick={() => { handleDownloadShiftReport('verification') }}
                            >
                                <IoCloudDownloadOutline size={18} className="me-2" />
                                Download Verification Data
                            </Button> */}
                        </div>
                        {/* {requestedData.length > 0 &&
                            <Card className="p-4 shadow-lg border-0 rounded-4">
                                <h6 className="fw-bold text-primary mb-4">({selectedValue?.label}) Download Details</h6>
                                <Card className="p-3 mb-4 border-0 shadow-sm bg-light">
                                    <Row className="text-center">
                                        <Col>
                                            <LuCalendarClock size={20} className="text-secondary" />
                                            <p className="mb-1 fw-bold text-secondary">Shift</p>
                                            <p className="mb-0">{selectedValue?.label}</p>
                                        </Col>
                                        <Col>
                                            <BsClockFill size={20} className="text-secondary" />
                                            <p className="mb-1 fw-bold text-secondary">Start Time</p>
                                            <p className="mb-0">
                                                {moment(selectedValue?.startTime).format("DD MMM YYYY, hh:mm A")}
                                            </p>

                                        </Col>
                                        <Col>
                                            <BsClockFill size={20} className="text-secondary" />
                                            <p className="mb-1 fw-bold text-secondary">End Time</p>
                                            {moment(selectedValue?.endTime).format("DD MMM YYYY, hh:mm A")}
                                        </Col>
                                    </Row>
                                </Card>

                                <div className="position-relative">
                                    <ProgressBar
                                        now={requestedData[requestedData?.length - 1]?.progress}
                                        variant="danger"
                                        striped
                                        animated
                                        style={{ height: "10px", borderRadius: "10px" }}
                                    />
                                    <div
                                        className="position-absolute"
                                        style={{
                                            top: "-5px",
                                            left: `${requestedData[requestedData?.length - 1]?.progress}%`,
                                            transform: "translateX(-50%)",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                backgroundColor: "#8B0000",
                                                borderRadius: "50%",
                                                border: "3px solid white",
                                                boxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                <p className="mt-2 text-muted">
                                    {requestedData[requestedData?.length - 1]?.progress === 100 ? (
                                        <span className="text-danger fw-bold">Download completed!</span>
                                    ) : (
                                        <>
                                            Downloading... <span className="fw-bold">{requestedData[requestedData?.length - 1]?.progress}%</span>{" "}
                                            completed
                                        </>
                                    )}
                                </p>
                                <div className="d-flex justify-content-end">
                                    <Form.Text>Last Download Proccess Time: {new Date(requestedData[requestedData?.length - 1]?.createdAt).toLocaleString()}</Form.Text>
                                </div>
                            </Card>
                        } */}
                    </div>
                }
            </div>
            <div className="mt-3">
                <Row>
                    <Col>
                        <Card
                            className="mt-3"
                            style={{
                                backgroundColor: "#f9f9f9",
                                borderRadius: "12px",
                                border: "none",
                            }}
                        >
                            <Card.Body>
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: 14 }}>Sr. No</th>
                                            <th style={{ fontSize: 14 }}>Exam Name</th>
                                            <th style={{ fontSize: 14 }}>Shift</th>
                                            <th style={{ fontSize: 14 }}>Progress %</th>
                                            {/* <th style={{ fontSize: 14 }}>File Link</th> */}
                                            <th style={{ fontSize: 14 }}>Data Type</th>
                                            <th style={{ fontSize: 14 }}>Email Sent</th>
                                            <th style={{ fontSize: 14 }}>Email Sent to</th>
                                            <th style={{ fontSize: 14 }}>Proccess Start Time</th>
                                            <th style={{ fontSize: 14 }}>Proccess End Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {requestedData && requestedData.length > 0 ? (
                                            requestedData
                                                // .filter((data: any) => data?.progress === 100)
                                                .map((data: any, index: number) => {
                                                    return (
                                                        <tr key={data._id || index}>
                                                            <td style={{ fontSize: 13 }}>{index + 1}</td>
                                                            <td style={{ fontSize: 13 }}>{data?.examId?.name || "N/A"}</td>
                                                            <td style={{ fontSize: 13 }}>{data?.examId?.shifts?.find((shift: any) => shift?._id === data?.shiftId)?.shiftName || "N/A"}</td>
                                                            <td style={{ fontSize: 13 }}>{data?.progress || "N/A"}</td>
                                                            {/* <td style={{ fontSize: 13 }}>
                                                                <IoCloudDownloadOutline
                                                                className="text-primary"
                                                                style={{ cursor: "pointer" }}
                                                                size={20}
                                                                onClick={() => handleUpdateRequestCount(data?._id, data?.link)}
                                                                />
                                                                </td> */}
                                                            <td style={{ fontSize: 13 }}>{data?.type || "N/A"}</td>
                                                            <td style={{ fontSize: 13 }}>{data?.emailSent ? "Sent" : "Not Sent"}</td>
                                                            <td style={{ fontSize: 13 }}>{data?.emailTo || "N/A"}</td>
                                                            <td style={{ fontSize: 13 }}>{new Date(data?.createdAt).toLocaleString() || "N/A"}</td>
                                                            <td style={{ fontSize: 13 }}>{new Date(data?.updatedAt).toLocaleString() || "N/A"}</td>
                                                        </tr>
                                                    );
                                                })
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>


                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                show={showReportModal}
                onHide={() => { setShowReportModal(false); setDownloadType('') }}
                centered
                animation={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            downloadType ?
                                "Shift Verifications" :
                                "Shift Report"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            Enter the email to receive the report.
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowReportModal(false); setDownloadType('') }}>
                        Close
                    </Button>
                    <Button variant="success">
                        Process
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
